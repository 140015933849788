.single {
  display: flex;
  width: 100%;

  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;

        .buttons {
          position: absolute;
          justify-content: space-around;
          top: 0;
          right: 0;

          .backButton {
            position: absolute;
            top: 0;
            right: 60px;
            width: 40px;
            height: 25px;
            padding: 8px;
            font-size: 14px;
            text-align: center;
            color: #982998;
            background-color: #e8d9e8;
            cursor: pointer;
            border-radius: 0 0 5px 5px;

            &:hover {
              background-color: #efc2ef;
            }
          }

          .editButton {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 25px;
            padding: 8px;
            font-size: 14px;
            text-align: center;
            color: #982998;
            background-color: #e8d9e8;
            cursor: pointer;
            border-radius: 0 0 5px 5px;

            &:hover {
              background-color: #efc2ef;
            }
          }
        }



        .item {
          display: flex;
          gap: 20px;

          .itemImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }

          .details {
            margin-left: 80px;

            .itemTitle {
              margin-bottom: 10px;
              color: gray;
            }

            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;

              .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 5px;
              }

              .itemValue {
                font-weight: 300;
              }
            }
          }
        }
      }

      .right {
        flex: 2;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }

}