.profile {
  display: flex;
  width: 100%;

  .profileContainer {
    flex: 6;

  }
}

