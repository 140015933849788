.app.dark {
  background-color: #343333;
  color: rgb(217, 212, 212);

  .chartGrid {
    stroke: rgb(217, 212, 212);
  }

  .table {
    background-color: #343333;

    .tableCell {
      color: rgb(217, 212, 212);
    }
  }

  .navbar {
    color: rgb(217, 212, 212);
    border-color: #424141;

    .search {
      border: 2px solid #575555;

      input, select, textarea {
        color: rgb(217, 212, 212);
      }
    }
  }

  .sidebar {
    background-color: #343333;
    border-color: #424141;

    .top {
      .logo {
        color: rgb(217, 212, 212);
      }
    }

    hr {
      border-color: #424141;
    }

    ul {


      li {
        &:hover {
          background-color: #b6b3b3;

          .icon {
            color: #343333;
          }

          span {
           color: #343333;
          }
        }

        .icon {
          color: rgb(180, 173, 173);
        }
      }
    }
  }

  .datatable {
    .datagrid {
      color: rgb(217, 212, 212);
      border: none;

      .viewButton  {
        color: rgb(217, 212, 212);
        border: 1px solid #1976d2;
      }

      .deleteButton {
        color: rgb(217, 212, 212);
        border: 1px solid #b90808;
      }
    }
  }
  input, select, textarea {
    background-color: transparent;
    color: rgb(217, 212, 212);
  }
}


