.navbar {
  height: 50px;
  border-bottom: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }

  .search {
    display: flex;
    align-items: center;
    border: 0.5px solid lightgray;
    padding: 3px;

    .searchIcon {
      cursor: pointer;
    }

    input {
      border: none;
      outline: none;
      background: transparent;

      &::placeholder {
        font-size: 12px;
      }
    }
  }

  .items {
    display: flex;
    align-items: center;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      position: relative;

      .icon {
        font-size: 20px;
        cursor: pointer;
      }

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        -webkit-box-shadow: 5px 1px 8px 0px rgba(0,0,0,0.71);
        box-shadow: 5px 1px 8px 0px rgba(0,0,0,0.71);
        cursor: pointer;
      }

      .avatar:hover {
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        transition: 200.0ms;
      }

      .counter {
        width: 15px;
        height: 15px;
        background-color: #c02a2a;
        border-radius: 50%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: bold;
        position: absolute;
        top: -5px;
        right: -5px;
      }
    }
  }
}