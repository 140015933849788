.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 1;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
  }

.modal__content {
  padding: 20px;
  border-radius: 15px;
  background-color: white;
  transform: scale(0.5);
  transition: 0.4s all;
  min-width: 250px;
  //width: 50vw;
}

.modal__content.active {
  transform: scale(1);
    }







