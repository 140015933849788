.datatable {
  height: 600px;
  padding: 20px;

  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      //text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: green;
        color: white;
      }
    }
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      color: #24dc24;
      background-color: #074b07;
    }

    &.passive {
      color: #f3c0c0;
      background-color: #861818;
    }

    &.pending {
      color: #f1d389;
      background-color: #6c5f14;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: #1976d2;
      border: 1px solid #1976d2;
      cursor: pointer;

      &:hover {
        background: #1976d2;
        color: white;
      }
    }

    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkred;
      border: 1px solid darkred;
      cursor: pointer;

      &:hover {
        background: darkred;
        color: white;
      }
    }

    .completeButton {
      padding: 2px 55px;
      border-radius: 5px;
      color: darkgreen;
      border: 1px solid darkgreen;
      cursor: pointer;

      &:hover {
        background: darkgreen;
        color: white;
      }
    }
  }

  .modalWindow {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .modalText {
      font-size: 20px;
      color: black;
    }

    .modalButtons {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 50px;

      .modalOk {
        padding: 5px 5px;
        width: 50px;
        border-radius: 5px;
        color: green;
        border: 1px solid green;
        cursor: pointer;

        &:hover {
          background: green;
          color: white;
        }
      }

      .modalCancel {
        padding: 5px 5px;
        border-radius: 5px;
        color: darkred;
        border: 1px solid darkred;
        cursor: pointer;

        &:hover {
          background: darkred;
          color: white;
        }
      }
    }
  }
}