.sidebar {
  flex: 1;
  border-right: 0.5px solid lightgray;
  min-height: 100vh;
  background-color: white;

  .top {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;

    .logo {
      font-size: 16px;
      font-weight: bold;
      color: #1976d2;
    }
  }
  hr {
    height: 0;
    border: 0.5px solid lightgray;
  }
  .center {
    padding-left: 20px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 10px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        &:hover {
          background-color: #d7e5f5;
        }

        .icon {
          font-size: 18px;
          color: #1976d2;
        }

        span {
          font-size: 15px;
          font-weight: 600;
          color: #888;
          margin-left: 10px;
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    margin: 10px 20px;

    .colorOptions {
      width: 25px;
      height: 25px;
      border-radius: 5px;
      border: 1.5px solid #1976d2;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #3c3c42;
      }
    }
  }
}